import { getOrderlineList } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { SALES_CUSTOMER_CARE_SCOPE } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export async function useLastOrderLines() {
  const { logHttpOnEvent } = useSalesCustomerCareLogger()

  return useHttpFetch(getOrderlineList, {
    queryParams: {
      pageSize: 4,
    },
    immediate: true,
    onEvent: (event, context) =>
      logHttpOnEvent({
        event,
        context,
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.helpCenter} Error when fetching the last orders`,
      }),
  })
}
