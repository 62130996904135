import { type Ref, watch } from 'vue'

import { getArticlesSearch } from '@backmarket/http-api/src/api-specs-help-center/help-center/help-center'
import { useHttpLazyFetch } from '@backmarket/nuxt-module-http/useHttpLazyFetch'

export function useFAQArticlesSearch({ query }: { query: Ref<string> }) {
  const { data, error, execute } = useHttpLazyFetch(getArticlesSearch, {
    queryParams: {
      query,
    },
    immediate: false,
    watch: false,
  })

  watch(query, () => {
    if (query.value.trim?.() !== '') {
      void execute()
    } else {
      data.value = []
    }
  })

  return { data, error }
}
