import { getCategories } from '@backmarket/http-api/src/api-specs-help-center/help-center/help-center'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { SALES_CUSTOMER_CARE_SCOPE } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export async function useFAQCategories() {
  const { logHttpOnEvent } = useSalesCustomerCareLogger()

  return useHttpFetch(getCategories, {
    immediate: true,
    onEvent: (event, context) =>
      logHttpOnEvent({
        event,
        context,
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.helpCenter} Error when fetching FAQ categories`,
      }),
  })
}
